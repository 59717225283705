import dynamic from 'next/dynamic';
import { Box, Container, Stack } from '@mui/material';
import { QuotebeamFeatures } from './components/QuotebeamFeatures';
const WhyQuotebeamAnimation = dynamic(() => import('./components/WhyQuotebeamAnimation').then(mod => mod.WhyQuotebeamAnimation), {
  loading: () => <Box sx={{
    width: 600,
    height: 400
  }} />
});
export const WhyQuotebeam = () => {
  return <Box component="section" sx={{
    bgcolor: 'common.white'
  }} data-sentry-element="Box" data-sentry-component="WhyQuotebeam" data-sentry-source-file="WhyQuotebeam.tsx">
      <Container sx={{
      pt: {
        xs: 4,
        lg: 6
      }
    }} data-sentry-element="Container" data-sentry-source-file="WhyQuotebeam.tsx">
        <Stack direction={{
        xs: 'column',
        md: 'row'
      }} spacing={{
        xs: 0,
        md: 4
      }} sx={{
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      }} data-sentry-element="Stack" data-sentry-source-file="WhyQuotebeam.tsx">
          <Box sx={{
          maxWidth: 490,
          flex: 1
        }} data-sentry-element="Box" data-sentry-source-file="WhyQuotebeam.tsx">
            <QuotebeamFeatures data-sentry-element="QuotebeamFeatures" data-sentry-source-file="WhyQuotebeam.tsx" />
          </Box>
          <Box sx={{
          maxWidth: 730,
          mr: 4,
          flex: 1
        }} data-sentry-element="Box" data-sentry-source-file="WhyQuotebeam.tsx">
            <WhyQuotebeamAnimation data-sentry-element="WhyQuotebeamAnimation" data-sentry-source-file="WhyQuotebeam.tsx" />
          </Box>
        </Stack>
      </Container>
    </Box>;
};