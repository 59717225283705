'use client';

import { useQuery } from '@tanstack/react-query';
import { Box } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { webQueries } from '@qb/httpRequest/webQueries';
import { PartCategoryTrendingCategoriesResponse } from '@/shared/types/controllers/PartCategoryControllerTypes';
import { PartPopularPartsResponse } from '@/shared/types/controllers/PartControllerTypes';
import { FAQ } from '@/src/components/FAQ/FAQ';
import { AssociationsBanner } from '../Common/AssociationsBanner/AssociationsBanner';
import { ColoredDividerVariant1 } from './components/ColoredDividers/ColoredDividerVariant1';
import { ColoredDividerVariant2 } from './components/ColoredDividers/ColoredDividerVariant2';
import { Hero } from './components/Hero/Hero';
import { HeroImageDivider } from './components/Hero/HeroImageDivider';
import { PopularProducts } from './components/PopularProducts/PopularProducts';
import { RoboticBackgroundImage } from './components/RoboticBackgroundImage/RoboticBackgroundImage';
import { SearchBannerDesktop } from './components/SearchBanner/SearchBannerDesktop';
import { SearchBannerMobile } from './components/SearchBanner/SearchBannerMobile';
import { Testimonials } from './components/Testimonials/Testimonials';
import { TrendingCategories } from './components/TrendingCategories/TrendingCategories';
import { TrustedBrands } from './components/TrustedBrands/TrustedBrands';
import { WhyQuotebeam } from './components/WhyQuotebeam/WhyQuotebeam';
type Props = PartCategoryTrendingCategoriesResponse & {
  popularParts: PartPopularPartsResponse['parts'];
};
export const Homepage = ({
  popularParts: initialPopularParts,
  trendingCategories
}: Props) => {
  const isMobile = useIsMobile();
  const {
    data: popularParts
  } = useQuery({
    ...webQueries.part.popularParts,
    initialData: initialPopularParts
  });
  return <>
      <Hero data-sentry-element="Hero" data-sentry-source-file="Homepage.tsx" />
      <RoboticBackgroundImage data-sentry-element="RoboticBackgroundImage" data-sentry-source-file="Homepage.tsx">
        <PopularProducts popularParts={popularParts} data-sentry-element="PopularProducts" data-sentry-source-file="Homepage.tsx" />
        <TrendingCategories trendingCategories={trendingCategories} data-sentry-element="TrendingCategories" data-sentry-source-file="Homepage.tsx" />
      </RoboticBackgroundImage>
      <TrustedBrands data-sentry-element="TrustedBrands" data-sentry-source-file="Homepage.tsx" />
      <ColoredDividerVariant1 data-sentry-element="ColoredDividerVariant1" data-sentry-source-file="Homepage.tsx" />
      <WhyQuotebeam data-sentry-element="WhyQuotebeam" data-sentry-source-file="Homepage.tsx" />
      <AssociationsBanner logosHeight={84} titleSx={{
      typography: {
        xs: 'h4',
        md: 'h3'
      }
    }} data-sentry-element="AssociationsBanner" data-sentry-source-file="Homepage.tsx" />
      <HeroImageDivider data-sentry-element="HeroImageDivider" data-sentry-source-file="Homepage.tsx" />
      <Testimonials data-sentry-element="Testimonials" data-sentry-source-file="Homepage.tsx" />
      <ColoredDividerVariant2 data-sentry-element="ColoredDividerVariant2" data-sentry-source-file="Homepage.tsx" />
      <Box component="section" sx={{
      bgcolor: 'common.white'
    }} data-sentry-element="Box" data-sentry-source-file="Homepage.tsx">
        <FAQ isHomePage data-sentry-element="FAQ" data-sentry-source-file="Homepage.tsx" />
      </Box>
      {isMobile ? <SearchBannerMobile /> : <SearchBannerDesktop />}
    </>;
};