'use client';

import { CSSProperties } from 'react';
import Image, { StaticImageData } from 'next/image';
import { ChevronRight } from '@mui/icons-material';
import { Box, Button, Card, CardActionArea, CardContent, Container, SxProps, Typography, useTheme } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { PartCategoryTrendingCategoriesResponse } from '@/shared/types/controllers/PartCategoryControllerTypes';
import { generatePartCategorySearchURL } from '@/shared/utils/URLs';
import cablesAndConnectors from './assets/cablesAndConnectors.png';
import circuitBreakers from './assets/circuitBreakers.png';
import ioBlocks from './assets/ioBlocks.png';
import plcModules from './assets/plcModules.png';
import sensors from './assets/sensors.png';
import variableFrequencyDrives from './assets/variableFrequencyDrives.png';
type Category = {
  id: number;
  name: string;
  image: StaticImageData;
  url: string;
};
const hardcodedList: Omit<Category, 'url'>[] = [{
  id: 314,
  name: 'Miniature Circuit Breakers',
  image: circuitBreakers
}, {
  id: 614,
  name: 'PLC Modules',
  image: plcModules
}, {
  id: 378,
  name: 'Cables and Connectors',
  image: cablesAndConnectors
}, {
  id: 388,
  name: 'Proximity Sensors',
  image: sensors
}, {
  id: 360,
  name: 'Variable Frequency Drives',
  image: variableFrequencyDrives
}, {
  id: 612,
  name: 'I/O\nBlocks',
  image: ioBlocks
}];
export const TrendingCategories = ({
  trendingCategories = []
}: PartCategoryTrendingCategoriesResponse) => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const list: Category[] = hardcodedList.map(hardcodedCategory => {
    const trendingCategory = trendingCategories.find(c => c.id === hardcodedCategory.id);
    return {
      ...trendingCategory,
      ...hardcodedCategory,
      url: generatePartCategorySearchURL(trendingCategory?.name || hardcodedCategory.name, trendingCategory?.id || hardcodedCategory.id)
    };
  });
  return <Box component="section" data-sentry-element="Box" data-sentry-component="TrendingCategories" data-sentry-source-file="TrendingCategories.tsx">
      <Container sx={{
      py: {
        xs: 4,
        md: 6
      }
    }} data-sentry-element="Container" data-sentry-source-file="TrendingCategories.tsx">
        <Typography component="h2" sx={{
        textAlign: 'center',
        typography: {
          xs: 'h4',
          md: 'h3'
        },
        mb: {
          xs: 3,
          md: 4
        }
      }} data-sentry-element="Typography" data-sentry-source-file="TrendingCategories.tsx">
          Trending automation parts types & categories
        </Typography>

        <Box sx={{
        display: 'grid',
        gridTemplateColumns: {
          xs: 'repeat(2, 1fr)',
          md: 'repeat(6, 1fr)'
        },
        gridTemplateRows: {
          xs: 'repeat(4, 176px)',
          md: 'repeat(2, 136px)'
        },
        gap: {
          xs: 1,
          md: 2,
          lg: 3
        }
      }} data-sentry-element="Box" data-sentry-source-file="TrendingCategories.tsx">
          <CategoryCard category={list[0]} sx={{
          gridColumn: {
            xs: 'span 2',
            md: 'span 2'
          },
          gridRow: {
            xs: 'span 1',
            md: 'span 2'
          }
        }} data-sentry-element="CategoryCard" data-sentry-source-file="TrendingCategories.tsx" />
          <CategoryCard category={list[1]} sx={{
          gridColumn: 'span 1',
          gridRow: 'span 1'
        }} contentWrapperSx={{
          alignItems: 'center'
        }} imageCSSProperties={{
          objectPosition: 'center right'
        }} data-sentry-element="CategoryCard" data-sentry-source-file="TrendingCategories.tsx" />
          <CategoryCard category={list[2]} sx={{
          gridColumn: 'span 1'
        }} imageWrapperSx={{
          mt: -2,
          height: '100%',
          width: '100%'
        }} contentWrapperSx={{
          flexDirection: 'column-reverse'
        }} data-sentry-element="CategoryCard" data-sentry-source-file="TrendingCategories.tsx" />
          <CategoryCard category={list[3]} sx={{
          gridColumn: 'span 2',
          alignItems: 'flex-end'
        }} contentWrapperSx={{
          flexDirection: 'row-reverse',
          p: 0,
          pt: 2,
          pl: 0,
          pb: 2
        }} textWrapperSx={{
          alignSelf: 'flex-end'
        }} imageWrapperSx={{
          height: 150,
          mt: -2
        }} imageCSSProperties={{
          objectPosition: 'center left'
        }} data-sentry-element="CategoryCard" data-sentry-source-file="TrendingCategories.tsx" />
          <CategoryCard category={list[4]} sx={{
          gridColumn: {
            xs: 'span 1',
            md: 'span 2'
          }
        }} textWrapperSx={{
          alignSelf: 'flex-end'
        }} contentWrapperSx={{
          flexDirection: {
            xs: 'column-reverse',
            md: 'row'
          }
        }} data-sentry-element="CategoryCard" data-sentry-source-file="TrendingCategories.tsx" />
          <CategoryCard category={list[5]} sx={{
          gridColumn: 'span 1'
        }} contentWrapperSx={{
          flexDirection: 'column',
          whiteSpace: 'nowrap'
        }} data-sentry-element="CategoryCard" data-sentry-source-file="TrendingCategories.tsx" />

          {isMobile ? <Button href="/search/partCategory" variant="contained" endIcon={<ChevronRight />} size="large" sx={{
          gridColumn: 'span 2',
          mt: 2
        }}>
              View all categories
            </Button> : <Card sx={{
          backgroundColor: 'primary.main',
          gridColumn: 'span 1',
          transition: theme.transitions.create(['background-color', 'font-weight']),
          '&:hover': {
            backgroundColor: 'primary.dark',
            '& p': {
              fontWeight: 600
            }
          }
        }}>
              <CardActionArea href="/search/partCategory" sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%'
          }}>
                <Typography sx={{
              fontSize: 15,
              fontWeight: 500,
              color: 'primary.contrastText'
            }}>
                  All categories
                </Typography>
                <ChevronRight sx={{
              ml: 1,
              color: 'primary.contrastText'
            }} />
              </CardActionArea>
            </Card>}
        </Box>
      </Container>
    </Box>;
};
type CategoryCardProps = {
  category: Category;
  sx?: SxProps;
  imageWrapperSx?: SxProps;
  imageCSSProperties?: CSSProperties;
  contentWrapperSx?: SxProps;
  textWrapperSx?: SxProps;
};
const CategoryCard = ({
  category,
  sx,
  imageWrapperSx = {},
  imageCSSProperties = {},
  contentWrapperSx = {},
  textWrapperSx = {}
}: CategoryCardProps) => {
  const theme = useTheme();
  return <Card sx={{
    position: 'relative',
    height: '100%',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'primary.4p',
    transition: theme.transitions.create(['background-color', 'font-weight']),
    '&:hover': {
      backgroundColor: 'primary.50p',
      '& h3': {
        fontWeight: 700
      }
    },
    ...sx
  }} data-sentry-element="Card" data-sentry-component="CategoryCard" data-sentry-source-file="TrendingCategories.tsx">
      <CardActionArea href={category.url} sx={{
      height: '100%',
      width: '100%'
    }} data-sentry-element="CardActionArea" data-sentry-source-file="TrendingCategories.tsx">
        <CardContent sx={{
        p: 2,
        gap: 1,
        height: '100%',
        width: '100%',
        display: 'flex',
        '&:last-child': {
          pb: 0
        },
        ...contentWrapperSx
      }} data-sentry-element="CardContent" data-sentry-source-file="TrendingCategories.tsx">
          <Typography variant="h4" component="h3" sx={{
          width: 'fit-content',
          ...textWrapperSx
        }} data-sentry-element="Typography" data-sentry-source-file="TrendingCategories.tsx">
            {category.name}
          </Typography>
          <Box sx={{
          position: 'relative',
          height: '100%',
          width: '100%',
          flexGrow: 1,
          ...imageWrapperSx
        }} data-sentry-element="Box" data-sentry-source-file="TrendingCategories.tsx">
            <Image src={category.image} alt={category.name} fill style={{
            objectFit: 'contain',
            objectPosition: 'top right',
            ...imageCSSProperties
          }} sizes="(max-width: 600px) 40vw, 30vw" data-sentry-element="Image" data-sentry-source-file="TrendingCategories.tsx" />
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>;
};