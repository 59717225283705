'use client';

import Image from 'next/image';
import { Box, Typography, useTheme } from '@mui/material';
import { HeroOmniSearch } from '@/src/components/OmniSearch/HeroOmniSearch';
import logoQB from './assets/logoQB.svg';
import searchBackground from './assets/searchBackground.png';
export const SearchBannerDesktop = () => {
  const theme = useTheme();
  return <Box sx={{
    minHeight: 385,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center'
  }} data-sentry-element="Box" data-sentry-component="SearchBannerDesktop" data-sentry-source-file="SearchBannerDesktop.tsx">
      <Image src={searchBackground} alt="Ready to find the parts you needed yesterday?" fill fetchPriority="low" placeholder="blur" style={{
      objectFit: 'cover'
    }} quality={100} sizes={`(max-width: ${theme.breakpoints.values.sm}px) 90vw, (max-width: ${theme.breakpoints.values.md}px) 40vw, 600px`} data-sentry-element="Image" data-sentry-source-file="SearchBannerDesktop.tsx" />
      <Box sx={{
      mt: 6
    }} data-sentry-element="Box" data-sentry-source-file="SearchBannerDesktop.tsx">
        <Typography component="h2" variant="h3" sx={{
        color: 'common.white',
        mb: 4,
        maxWidth: 450,
        position: 'relative',
        zIndex: 1
      }} data-sentry-element="Typography" data-sentry-source-file="SearchBannerDesktop.tsx">
          Ready to find the parts you needed yesterday?
        </Typography>

        <Box sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center'
      }} data-sentry-element="Box" data-sentry-source-file="SearchBannerDesktop.tsx">
          <Box sx={{
          height: 245,
          width: 241,
          position: 'absolute',
          right: -80
        }} data-sentry-element="Box" data-sentry-source-file="SearchBannerDesktop.tsx">
            <Image src={logoQB} alt="Quotebeam logo" fill style={{
            objectFit: 'contain'
          }} sizes="241px" data-sentry-element="Image" data-sentry-source-file="SearchBannerDesktop.tsx" />
          </Box>
          <Box sx={{
          minWidth: {
            xs: 300,
            md: 573,
            lg: 700
          },
          width: '100%'
        }} data-sentry-element="Box" data-sentry-source-file="SearchBannerDesktop.tsx">
            <HeroOmniSearch searchInputName="HomePageSearchBanner" inputSx={{
            height: [42, 83],
            borderRadius: [0, 40],
            fontSize: [16, 18],
            backgroundColor: 'common.common.white',
            px: [1, 3],
            '& .MuiInputAdornment-root .MuiSvgIcon-root': {
              fontSize: [24, 40],
              color: 'secondary.main'
            }
          }} magicIconSx={{
            width: 47,
            borderRadius: '0 40px 40px 0'
          }} data-sentry-element="HeroOmniSearch" data-sentry-source-file="SearchBannerDesktop.tsx" />
          </Box>
        </Box>
      </Box>
    </Box>;
};