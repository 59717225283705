'use client';

import { useEffect } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { recordEvent } from '@qb/frontend/analytics/ga4';
import { getGA4EcommerceListItem } from '@qb/frontend/analytics/utils';
import { PartPopularPartsResponse } from '@/shared/types/controllers/PartControllerTypes';
import { BaseCarousel } from '@/src/components/Common/BaseCarousel/BaseCarousel';
import { PartCardLarge } from '@/src/components/Common/PartCards/PartCardLarge';
import { useElementIsInViewFirstTime } from '@/src/hooks/useElementIsInViewFirstTime';
const GA4_ITEM_LIST_NAME = 'popular_products';
type Props = {
  popularParts: PartPopularPartsResponse['parts'];
};
export const PopularProducts = ({
  popularParts
}: Props) => {
  const partsCount = popularParts?.length ?? 0;
  const {
    elementInViewRef,
    isInViewFirstTime
  } = useElementIsInViewFirstTime();
  useEffect(() => {
    if (partsCount === 0 || !isInViewFirstTime) {
      return;
    }
    recordEvent('view_item_list', {
      ecommerce: {
        item_list_id: GA4_ITEM_LIST_NAME,
        item_list_name: GA4_ITEM_LIST_NAME,
        items: popularParts.map((part, index) => getGA4EcommerceListItem({
          part,
          index
        }))
      }
    });
  }, [partsCount, popularParts, isInViewFirstTime]);
  if (partsCount === 0) {
    return null;
  }
  return <Container ref={elementInViewRef} component="section" sx={{
    pt: {
      xs: 4,
      md: 6,
      lg: 10
    }
  }} data-sentry-element="Container" data-sentry-component="PopularProducts" data-sentry-source-file="PopularProducts.tsx">
      <Typography component="h2" sx={{
      textAlign: 'center',
      typography: {
        xs: 'h4',
        md: 'h3'
      },
      mb: {
        xs: 3,
        md: 4
      }
    }} data-sentry-element="Typography" data-sentry-source-file="PopularProducts.tsx">
        Recommended automation parts & components
      </Typography>
      <Box sx={{
      px: [1, 1, 2]
    }} data-sentry-element="Box" data-sentry-source-file="PopularProducts.tsx">
        <BaseCarousel slidesCount={partsCount} slidesToShow={{
        xs: 2,
        sm: 3,
        md: 4,
        lg: 5,
        xl: 6
      }} data-sentry-element="BaseCarousel" data-sentry-source-file="PopularProducts.tsx">
          {popularParts.map((part, index) => <Box key={part.id} sx={{
          px: {
            xs: 1
          }
        }}>
              <PartCardLarge part={part} ga4data={{
            itemIndex: index,
            itemListName: GA4_ITEM_LIST_NAME
          }} />
            </Box>)}
        </BaseCarousel>
      </Box>
    </Container>;
};